import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import {
  post__homeButton,
  post__comments,
  post__footer,
} from './Post.module.scss';

const Post = ({ post }) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, canonical } = post.frontmatter;

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <Link className={post__homeButton} to="/">
        All Articles
      </Link>

      <div>
        <Content body={html} title={title} />
      </div>

      <div className={post__footer}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      <div className={post__comments}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
