import React from 'react';
import moment from 'moment';
import { meta__date } from './Meta.module.scss';

const Meta = ({ date }) => (
  <div>
    <p className={meta__date}>Published {moment(date).format('D MMM YYYY')}</p>
  </div>
);

export default Meta;
