import React from 'react';
import { Link } from 'gatsby';
import {
  tags as tagsStyle,
  tags__list,
  tags__listItem,
  tags__listItemLink,
} from './Tags.module.scss';

const Tags = ({ tags, tagSlugs }) => (
  <div className={tagsStyle}>
    <ul className={tags__list}>
      {tagSlugs &&
        tagSlugs.map((slug, i) => (
          <li className={tags__listItem} key={tags[i]}>
            <Link to={slug} className={tags__listItemLink}>
              {tags[i]}
            </Link>
          </li>
        ))}
    </ul>
  </div>
);

export default Tags;
